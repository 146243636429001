import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AdminLayout from '../layouts/Admin/Admin';

function App(props) {
	

	return <AdminLayout {...props} />;
}

export default App;
