import { lazy, Suspense } from 'react';

import React from 'react';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { Spinner } from 'reactstrap';

const Name = lazy(() => import('./containers/Name'));
const Contact = lazy(() => import('./containers/Contact'));
const DataType = lazy(() => import('./containers/DataType'));
const Services = lazy(() => import('./containers/Services'));
const ExistingModels = lazy(() => import('./containers/ExistingModels'));
const UploadAndTry = lazy(() => import('./containers/UploadAndTry'));
const Projects = lazy(() => import('./containers/Projects'));
const CreateTrainings = lazy(() => import('./containers/CreateTrainings'));

const LazyName = (props) => {
	return (
		<Suspense
			fallback={
				<div style={{ backgroundColor: '#19191A' }}>
					<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
				</div>
			}
		>
			<ErrorBoundary>
				<Name {...props} />
			</ErrorBoundary>
		</Suspense>
	);
};

const LazyContact = (props) => {
	return (
		<Suspense
			fallback={
				<div
					className="content"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: '#19191A',
					}}
				>
					<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" size="lg" />
				</div>
			}
		>
			<ErrorBoundary>
				<Contact {...props} />
			</ErrorBoundary>
		</Suspense>
	);
};

const LazyDataType = (props) => {
	return (
		<Suspense
			fallback={
				<div className="content" style={{ backgroundColor: '#19191A' }}>
					<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" size="lg" />
				</div>
			}
		>
			<ErrorBoundary>
				<DataType {...props} />
			</ErrorBoundary>
		</Suspense>
	);
};

const LazyServices = (props) => {
	return (
		<Suspense
			fallback={
				<div className="content" style={{ backgroundColor: '#19191A' }}>
					<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" size="lg" />
				</div>
			}
		>
			<ErrorBoundary>
				<Services {...props} />
			</ErrorBoundary>
		</Suspense>
	);
};

const LazyExistingModels = (props) => {
	return (
		<Suspense
			fallback={
				<div className="content" style={{ backgroundColor: '#19191A' }}>
					<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" size="lg" />
				</div>
			}
		>
			<ErrorBoundary>
				<ExistingModels {...props} />
			</ErrorBoundary>
		</Suspense>
	);
};

const LazyUploadAndTry = (props) => {
	return (
		<Suspense
			fallback={
				<div className="content" style={{ backgroundColor: '#19191A' }}>
					<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" size="lg" />
				</div>
			}
		>
			<ErrorBoundary>
				<UploadAndTry {...props} />
			</ErrorBoundary>
		</Suspense>
	);
};

const LazyProjects = (props) => {
	return (
		<Suspense
			fallback={
				<div className="content" style={{ backgroundColor: '#19191A' }}>
					<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" size="lg" />
				</div>
			}
		>
			<ErrorBoundary>
				<Projects {...props} />
			</ErrorBoundary>
		</Suspense>
	);
};

const LazyCreateTrainings = (props) => {
	return (
		<Suspense
			fallback={
				<div className="content" style={{ backgroundColor: '#19191A' }}>
					<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" size="lg" />
				</div>
			}
		>
			<ErrorBoundary>
				<CreateTrainings {...props} />
			</ErrorBoundary>
		</Suspense>
	);
};

const routes = [
	{
		path: '/',
		name: 'Name',
		component: LazyName,
		layout: '/admin',
	},
	{
		path: '/contact',
		name: 'Contact',
		component: LazyContact,
		layout: '/admin',
	},
	{
		path: '/select',
		name: 'Select Type Of Dataset',
		component: LazyDataType,
		layout: '/admin',
	},

	{
		path: '/services',
		name: 'Services',
		component: LazyServices,
		layout: '/admin',
	},
	{
		path: '/existing_models',
		name: 'Existing Models',
		component: LazyExistingModels,
		layout: '/admin',
	},
	{
		path: '/upload_and_try',
		name: 'Upload And Try',
		component: LazyUploadAndTry,
		layout: '/admin',
	},
	{
		path: '/projects',
		name: 'Projects',
		component: LazyProjects,
		layout: '/admin',
	},
	{
		path: '/projects/:id',
		name: 'Project Explorer',
		component: LazyCreateTrainings,
		layout: '/admin',
	},
];

export default routes;
