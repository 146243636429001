/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// nodejs library that concatenates classes
//import Suzuki_S from '../../assets/img/suzuki_s.png';
// reactstrap components
import { NavbarBrand, Navbar, Container, UncontrolledTooltip } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import routes from '../../routes';

class AdminNavbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			collapseOpen: false,
			modalSearch: false,
			color: 'navbar-transparent',
			brandText: '',
		};
	}
	componentDidMount() {
		window.addEventListener('resize', this.updateColor);
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateColor);
	}

	componentDidUpdate(prevProps) {
		let activeRoute;
		if (prevProps.location.pathname !== this.props.location.pathname) {
			this.getActiveRoute(this.props.location.pathname);
		}
	}

	getActiveRoute = (route) => {
		let activeRoute;
		let regex = new RegExp('projects/[0-9a-f]+');
		if (regex.test(this.props.location.pathname)) {
			activeRoute = 'PROJECT EXPLORER';
		} else {
			activeRoute = routes.filter((f) => f.path === route)[0]
				? routes.filter((f) => f.path === route)[0].name
				: 'NOT FOUND';
		}

		localStorage.setItem('brand_text', activeRoute);
		this.setState({ brandText: activeRoute });
	};

	// function that adds color white/transparent to the navbar on resize (this is for the collapse)
	updateColor = () => {
		if (window.innerWidth < 993 && this.state.collapseOpen) {
			this.setState({
				color: 'bg-white',
			});
		} else {
			this.setState({
				color: 'navbar-transparent',
			});
		}
	};
	// this function opens and closes the collapse on small devices
	toggleCollapse = () => {
		if (this.state.collapseOpen) {
			this.setState({
				color: 'navbar-transparent',
			});
		} else {
			this.setState({
				color: 'bg-white',
			});
		}
		this.setState({
			collapseOpen: !this.state.collapseOpen,
		});
	};
	// this function is to open the Search modal
	toggleModalSearch = () => {
		this.setState({
			modalSearch: !this.state.modalSearch,
		});
	};
	render() {
		return (
			<>
				<Navbar
					// className={classNames("navbar-absolute", {
					//   [this.state.color]:
					//     this.props.location.pathname.indexOf("full-screen-map") === -1,
					// })}
					expand="lg"
					style={{ backgroundColor: 'rgb(18, 17, 21)', position: 'fixed' }}
				>
					<Container fluid>
						<div className="navbar-wrapper">
							{/* <div
								className={classNames('navbar-toggle d-inline', {
									toggled: this.props.sidebarOpened,
								})}
							>
								<button className="navbar-toggler" type="button" onClick={this.props.toggleSidebar}>
									<span className="navbar-toggler-bar bar1" />
									<span className="navbar-toggler-bar bar2" />
									<span className="navbar-toggler-bar bar3" />
								</button>
							</div> */}
							<NavbarBrand
								href="#pablo"
								style={{ fontWeight: '600' }}
								onClick={(e) => e.preventDefault()}
							>
								{/* {this.state.brandText || localStorage.getItem('brand_text')} */}
								{''}
							</NavbarBrand>
						</div>
						{/* <div>
							<a onClick={() => this.props.signOut()}>
								<i
									className="fa fa-power-off fa-lg"
									aria-hidden="true"
									style={{ color: '#d13400', marginRight: 5 }}
								></i>
							</a>{' '}
						</div> */}
					</Container>
				</Navbar>
			</>
		);
	}
}

const mapStateToProps = (state) => ({});

// const mapDispatchToProps = (dispatch) => ({
// 	signOut: () => dispatch(signOut()),
// });

export default withRouter(AdminNavbar);
